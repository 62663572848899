import 'core-js/stable'
import './styles/styles.scss'


const isIE = /rv:11.0/i.test(navigator.userAgent)
const isOldEdge = /Edge\/\d./i.test(navigator.userAgent)
const lowQuality = isIE || isOldEdge
const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && navigator.userAgent.indexOf('CriOS') == -1 && navigator.userAgent.indexOf('FxiOS') == -1;
const isMobile = navigator.userAgent.match(/Tablet|iPad|Mobile|Windows Phone|Lumia|Android|webOS|iPhone|iPod|Blackberry|PlayBook|BB10|Opera Mini|\bCrMo\/|Opera Mobi/i) ? true : false


if (isMobile && window.innerWidth < 1024) {

    document.querySelector('body').classList.add('is-mobile')
    
    const swiperKeyFigures = new Swiper('.swiper.key-figures', {
        slidePerView: 1,
        spaceBetween: 20,
        navigation: {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next'
        },
    })
}



const emEl = document.querySelectorAll('em')


emEl.forEach( (em) => {
    let str = em.innerHTML.split("")

    em.innerHTML = ''
    str.forEach( (c) => {
        let span = document.createElement('span')
        span.innerHTML = c
        em.appendChild(span)
    })
})
